<template>
  <v-container v-if="!loading" id="user-view-table" fluid tag="section">
    <v-form
      @submit.stop.prevent="get_users_by_page()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row justify="center" align="center" class="mt-6">
        <v-text-field
          class="mx-3"
          dense
          hide-details
          outlined
          :label="$t('name')"
          v-model="name_filter"
          :style="isMobile() ? 'max-width: 25%' : 'max-width: 35%'"
        ></v-text-field>
        <v-select
          class="mx-3"
          style="max-width: 30%"
          outlined
          hide-details
          dense
          :label="$t('sort_by')"
          :items="
            filter_order_options.filter(
              (x) => x.partnerOnly == (userType == 0 ? false : true)
            )
          "
          v-model="filter_order_selected"
          item-text="text"
          item-value="index"
        >
        </v-select>
        <v-checkbox
          class="mx-3 mt-0"
          style="max-width: 20%"
          outlined
          hide-details
          dense
          v-model="allowedOnly"
        >
          <template v-slot:label>
            <span style="color: var(--dark)">{{
              $t("only_users_with_permission")
            }}</span>
          </template>
        </v-checkbox>
        <v-btn class="mx-3 secondary" type="submit">{{ $t("apply") }}</v-btn>
      </v-row>
    </v-form>
    <v-row class="mb-6">
      <v-tabs @change="user_type_changed" v-model="userType" centered>
        <v-tab>{{ $tc("investor", 2) }}</v-tab>
        <v-tab>{{ $tc("partner", 2) }}</v-tab>
      </v-tabs>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $t("name") }}</th>
          <th class="text-center">{{ $t("email") }}</th>
          <th v-if="userType > 0" class="text-center">{{ $tc("user", 2) }}</th>
          <th class="text-center">{{ $t("briefing_permission") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td class="text-center">{{ show_name(user) }}</td>
          <td class="text-center">{{ user.Email }}</td>
          <td v-if="userType > 0" class="text-center">
            {{ user.InvitedUsers.length != 0 ? user.InvitedUsers.length : "-" }}
          </td>
          <td class="text-center">
            <v-checkbox
              class="permisision-checkbox"
              v-model="user.BriefingPermission"
              @change="change_permission(user)"
            ></v-checkbox>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="total_pages"
      v-model="page"
    >
    </v-pagination>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "ManageUserCompanyView",

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    users: [],
    page: 1,
    total_pages: 1,
    userType: 0,
    allowedOnly: false,
    name_filter: "",
    valid: true,
    lazy: false,
    filter_order_selected: -1,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    filter_order_options() {
      return [
        {
          index: -1,
          partnerOnly: false,
          text: this.$t("name"),
        },
        {
          index: 0,
          partnerOnly: false,
          text: this.$t("email"),
        },
        {
          index: 1,
          partnerOnly: true,
          text: this.$tc("user", 2),
        },
      ];
    },
  },
  props: {
    company_prop: Object,
  },

  async created() {
    this.get_users_by_page();
  },

  methods: {
    user_type_changed() {
      this.get_users_by_page();
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    change_permission(user) {
      this.apiService
        .postRequest(
          `company/${
            this.userType == 0 ? "investor" : "partner"
          }-permission/change`,
          {
            UserId: user.Id,
            CompanyId: this.company_prop.CompanyId,
            Allowed: user.BriefingPermission,
          }
        )
        .then((resp) => {})
        .catch((error) => {});
    },
    show_name: function (user) {
      if (user.Name === null) {
        return "";
      } else {
        return user.Name.concat(" ").concat(user.LastName);
      }
    },
    changePage() {
      this.$router.push({
        path: `/companies/edit/${this.company_prop.CompanyId}`,
        query: { page: this.page },
      });
      this.get_users_by_page();
    },
    get_users_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        Order: this.filter_order_selected,
        AllowedOnly: this.allowedOnly,
        CompanyId: this.company_prop.CompanyId,
      };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      this.apiService
        .postRequest(
          `company/${
            this.userType == 0 ? "investor" : "partner"
          }-view/list/filter`,
          params
        )
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.users = json.Users;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
